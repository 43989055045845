a {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #4378e3;
  height: 4vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(20px);
  color: white;
}
.App-header a {
  text-decoration: none;
  cursor: pointer;
}

.App-title {
  color: #fff;
}
