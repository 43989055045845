.videoPreviewsContainer {
  height: 80vh;
  display: grid;
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

@media only screen and (min-width: 1000px) {
  .videoPreviewsContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.figureView {
  height: 100%;
  width: 100%;
}
